import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect} from "react";
import {
    setDisplayedSectors,
    updateDisplayedSectors
} from "../store/actionCreators/map";
import useProject from "./useProject";

/**
 * description Displayed Sectors:
 * these are objects that indicate the sectors wer want to display in the map,
 * each sector when display = true should display the bins of corresponding sector (from sectorId property)
 *
 *
 *
 * displayed sector structure:
 * {
 *      siteId:  ObjectId (String),
 *      sectorId: ObjectId (String),
 *      antennaModel:  <Antenna Type> String (example: 'TYPE_1X1' ),
 *      display: Boolean (New!!),
 *      conversion:{
 *          from:<Technology>> String,
 *          to:<Technology> String
 *      }
 * }
 */


/*
 * hook to restart the global redux  object when application starts.
 */
export function useInitDisplayedSectors() {
    const project = useProject();
    const setDisplayedSectors = useSetDisplayedSectors();
    const cleanDisplayedSectors = useCleanDisplayedSectors();

    useEffect(() => {
        const sites = project?.sites || [];
        const sectors = sites.flatMap(site => ([...site.preDesign.sectors, ...site.postDesign.sectors].map(sector => ({
            ...sector,
            siteId: site._id
        }))));
        if (sectors.length === 0) {
            cleanDisplayedSectors();
            return;
        }

        const initialDisplayedSectors = sectors.map(sector => ({
            antennaModel: sector.antennaModels[0],
                siteId: sector.siteId,
                sectorId: sector._id,
                display: false,
                conversion: {from: null, to: null,}
         }));
        setDisplayedSectors(initialDisplayedSectors);
    }, [project])
}

export function useCleanDisplayedSectors() {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(setDisplayedSectors([]));
    }, [dispatch])
}

export function useResetDisplayedSectors() {
    const dispatch = useDispatch();
    const displayedSectors = useDisplayedSectors();
    return useCallback(() => {
        dispatch(setDisplayedSectors(displayedSectors.map(ds => ({...ds, display: false}))));
    }, [dispatch])
}


export function useDisplayedSectors() {
    return useSelector(state => state.map.displayedSectors);
}

function useSetDisplayedSectors() {
    const dispatch = useDispatch();
    return useCallback((displayedSectors) => {
        dispatch(setDisplayedSectors(displayedSectors))
    }, [dispatch]);
}

function useUpdateDisplayedSectors() {
    const dispatch = useDispatch();
    return useCallback((displayedSectors) => {
        dispatch(updateDisplayedSectors(displayedSectors))
    }, [dispatch]);
}

export function useDisplayedSectorsActions() {
    return {
        displayedSectors: useDisplayedSectors(),
        updateDisplayedSectors: useUpdateDisplayedSectors(),
        setDisplayedSectors: useSetDisplayedSectors(),
        cleanDisplayedSectors: useCleanDisplayedSectors(),
        resetDisplayedSectors: useResetDisplayedSectors(),
    }
}
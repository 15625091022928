import React, {useEffect, useState} from 'react';
import {OverlayView} from '@react-google-maps/api';
import SectorsDialog from '../Sector/SectorsDialog'
import styled from "styled-components";
import {useDisplayedSectors} from "../../Hooks/displayedSectors";
import useProject from "../../Hooks/useProject";

const MarkerDiv = styled.div`
  &&{
      z-index: 1;
      &.marker .marker-wrapper:before {
        background-color: ${({backgroundColor}) => backgroundColor} !important;
      }
      &.marker{
        text-align: start;
      }
      .title{
        opacity: 0;
        transition: opacity 400ms ease-in;
        &:hover{
          opacity: 1;
        }
      }
      &.marker .marker-wrapper .pin .image{
         box-shadow: none;
        &::after{
          border-top-color:${({theme}) => theme.components.marker.color};
        }
      }
      &.marker .marker-wrapper .pin{
        border-color:${({theme}) => theme.components.marker.color};
        &::before{
            background-color:${({theme}) => theme.components.marker.color};
            border-color:${({theme}) => theme.components.marker.color};
        }
      }
  }
`;

const Title = styled.h3`
  color: #fff;
  position:relative;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  text-align: center;
  line-height: 30px;
`;

function Marker({clusterer, project, site}) {
    const projectId = useProject()._id;
    const [dialog, setDialog] = useState(false);
    const allDisplayedSectors = useDisplayedSectors();
    const displayedSectors = allDisplayedSectors.filter(displaySector => displaySector.siteId === site._id);
    const siteSectors = site.preDesign.sectors.concat(site.postDesign.sectors);
    const relevantDisplayedSectors = displayedSectors.filter(displayedSector => displayedSector.display);
    useEffect(() => {
        setDialog(false);
    }, [projectId]);


    function markerClick(ev) {
        if (siteSectors.length > 0)
            setDialog(!dialog);
    }

    function handleCloseDialog(ev) {
        setDialog(false);
    }

    return <OverlayView
        position={site.location}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
        <div style={{position: 'relative'}}>
            <MarkerDiv
                backgroundColor={relevantDisplayedSectors.length > 0 ? '#595763' : '#E42324'}
                onClick={markerClick}
                className="marker">
                <div className="title">{site.displayName}</div>
                <div className="marker-wrapper">
                    {site.checked && <div className="tag"><i className="fa fa-check"/></div>}
                    <div className="pin">
                        {/*<ImageCentered className="image" style={{backgroundImage: `url(${image}) `}}/>*/}
                        <Title className='image'>{relevantDisplayedSectors.length}</Title>
                    </div>
                </div>
            </MarkerDiv>
            <SectorsDialog
                displayed={dialog}
                onClose={handleCloseDialog}
                site={site}
            />
        </div>
    </OverlayView>;
}


export default (Marker);
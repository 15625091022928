import React, {useEffect, useState} from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import {useTranslation} from "react-i18next";
import {extractAddressFromGeocoder} from "../../controller/mapper";
import styled from "styled-components";
import PropTypes from "prop-types";
import SiteInputs from "./SiteInputs";

const CustomRow = styled.div`
  &.row {
    margin: 0;
    padding: 0;
  }
  .align-right {
    text-align: right;
    padding-right: 0;
    padding-left: 0;
  }
  && .is-invalid.form-control {
    border-bottom-color: ${({theme}) => theme.palette.error.main};
    //box-shadow: 0px 0px 0px 2px rgba(220, 59, 26, 0.5), inset 1px 0 5px rgba(0, 0, 0, 0.03);
  }
  span.required-error {
    color: ${({theme}) => theme.palette.error.main};
    margin-right: 2px;
  }
`;
const Edit = styled.i`
  font-family: "Lato", sans-serif;
  align-content: flex-end;
  &.fa.fa-pencil {
    border: 2px #595763 solid;
  }
  &.fa.fa-pencil:before {
    font-family: FontAwesome, serif;
    float: right;
    margin-left: 7px;
  }
  &.fa.fa-trash {
    border: 2px rgba(238, 16, 0, 0.64) solid;
    background-color: rgba(221, 0, 2, 0.1);
    color: rgb(110, 0, 2);
  }
  &.fa.fa-trash:before {
    font-family: FontAwesome, serif;
    float: right;
    margin-left: 7px;
  }
`;

const ButtonContainer = styled(Form.Label)`
  height: 90px;
  padding-top: 20px;
`;
const DEFAULT_SITE = {
    displayName: "",
    location: {lat: 31.771959, lng: 35.217018},
    address: "",
    siteNumber: NaN,
    siteName: "",
    area: "",
};

const DetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  .buttons-details-header {
    display: flex;
    justify-content: flex-end;
  }
`;

function SiteDetails({vertical, siteToManage, site, onGoToSite, button, nameError, onSiteChange, onDelete,}) {
    const [address, setAddress] = useState("");
    const {t} = useTranslation();
    useEffect(() => {
        if (window.google) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({location: site.location}, function (results, status) {
                if (status === "OK") {
                    setAddress(extractAddressFromGeocoder(results));
                }
            });
        }
    }, [site.location]);

    function handleLocationChange(location) {
        onSiteChange({...site, location});
    }

    function goToSite() {
        onGoToSite(siteToManage);
    }

    function nameChanged(ev) {
        onSiteChange({...site, displayName: ev.target.value});
    }

    return (
        <CustomRow className="row">
            <div className={"col-sm-12"}>
                <DetailsHeader>
                    <Card.Title>{t("Site_Details")}</Card.Title>
                    <div className="buttons-details-header">
                        {siteToManage && (
                            <Edit onClick={goToSite} className="btn btn-rounded fa fa-pencil">
                                {t("Manage_Site")}
                            </Edit>
                        )}
                        {typeof onDelete === "function" && (
                            <Edit onClick={onDelete} className="btn btn-rounded fa fa-trash">
                                {t("Delete_")}
                            </Edit>
                        )}
                    </div>
                </DetailsHeader>
                <Form.Label column={false}>
                    {t("Site_Name")}
                    {nameError ? <span className={"required-error"}> *</span> : ""}
                </Form.Label>
                <Form.Control
                    type="text"
                    placeholder={t("Enter_Site_Name")}
                    value={site.displayName}
                    onChange={nameChanged}
                    isInvalid={nameError}
                    isValid={Boolean(site.displayName)}
                />
            </div>
            <SiteInputs
                site={site}
                onSiteChange={onSiteChange}
                vertical={vertical}
                handleLocationChange={handleLocationChange}
                address={address}
            />
            <div className={"col-sm-8"}/>
            {button && <ButtonContainer className={"col-sm-4"}>{button}</ButtonContainer>}
        </CustomRow>
    );
}

SiteDetails.defaultProps = {
    site: DEFAULT_SITE,
    nameError: false,
};
SiteDetails.propTypes = {
    site: PropTypes.any.isRequired,
    button: PropTypes.element,
    siteToManage: PropTypes.object,
    onGoToSite: PropTypes.func,
    onSiteChange: PropTypes.func,
    vertical: PropTypes.bool,
    nameError: PropTypes.bool,
};
export default SiteDetails;

import React, {useCallback} from 'react';
import ColorMapping from "./ColorMapping";
import DistributionTable from "./DistributionTable";
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux';
import SelectAreaButton from "./SelectAreaButton";
import styled from "styled-components";
import useProject from "../../Hooks/useProject";
import Constants from "../../controller/Constants";
import AreaSelectionControls from "./AreaSelectionControls";
import HighlightOpacityButtons from "./HighlightOpacityButtons";
import MapLayerControls from "./MapLayerControls";
import useAsyncLoading from "../../Hooks/useAsyncLoading";
import AsyncLoader from "./AsyncLoader";
import {useShowCostsTable} from "../../Hooks/common";
import PredictionSwitch from "./PredictionSwitch";
import SearchLocation from "./SearchLocation";
import {useLocationStatsState} from "../../Hooks/map";

const Div = styled.div`
    background-color: ${({theme}) => theme.palette.primary.main};
    color: ${({theme}) => theme.palette.common.white};
    position:absolute;
    ${props => props.top ? 'top: ' + props.top + ';' : ''}
    ${props => props.bottom ? 'bottom: ' + props.bottom + ';' : ''}
    ${props => props.left ? 'left: ' + props.left + ';' : ''}
    ${props => props.right ? 'right: ' + props.right + ';' : ''}
    transform:translateX(-50%);
    width:auto;
    z-index: 99;
    border-radius: 0;
    //background: rgba(250, 250, 250,1);
    padding: 0 20px;
    border:none;
    box-shadow: ${({theme}) => theme.shadows.map.control};
    &.zoom{
        padding: 0;
        text-align: center;
        width: 40px;
        span{
          font-size:15px;
        }
    }
    h4{
        margin-bottom: 4px;
    }
`;

function MapControls({requireLogin, projectFetched, isCostsTable}) {
    // const [locationStats,setLocationStats] = useLocationStatsState();
    const showCostsTable = useShowCostsTable();
    const {user, displayedSectors, zoom, mapState, mapLoaded} = useSelector(mapStateToProps);
    const isAreaSelection = mapState === Constants.mapStates.AREA_SELECTION;
    const isColorMapper = projectFetched && !requireLogin;
    const isDistributionTable = projectFetched && !requireLogin && isCostsTable && !isAreaSelection && (displayedSectors.length > 0);
    const isSelectAreaButton = projectFetched && !requireLogin;
    const closeTable = useCallback(() => showCostsTable(false), []);
    const {loading, progress} = useAsyncLoading();
    const project = useProject();
    return Boolean(user.isAuthenticated && mapLoaded) && <>
        {Boolean(zoom) && <Div className={'zoom'} left={'calc(100% - 170px)'} top={'calc(100% - 120px)'}>
            <span title={'Map Zoom'}>{zoom}</span>
        </Div>}
        {user.isAuthenticated && project.name && <Div top={'10px'} left={'50%'}><h4 style={{marginTop: 5}}>{project.name}</h4></Div>}
        <MapLayerControls/>
        {Boolean(isColorMapper) && <ColorMapping/>}
        {Boolean(isDistributionTable) && <DistributionTable onClose={closeTable}/>}
        {Boolean(isSelectAreaButton) && <SelectAreaButton/>}
        {Boolean(isAreaSelection) && <AreaSelectionControls/>}
        {Boolean(isSelectAreaButton) && <HighlightOpacityButtons/>}
        {/*{locationStats.displayed && <LocationsStats/>}*/}
        <PredictionSwitch/>
        <AsyncLoader loading={loading} progress={progress}/>
        <SearchLocation/>
    </>
}

MapControls.propTypes = {
    requireLogin: PropTypes.bool.isRequired,
    projectFetched: PropTypes.bool.isRequired,
    isCostsTable: PropTypes.bool.isRequired,
};

function mapStateToProps(reduxState) {
    return {
        user: reduxState.user,
        displayedSectors: reduxState.map.displayedSectors,
        zoom: reduxState.map.mapZoom,
        mapState: reduxState.map.mapState,
        mapLoaded: reduxState.map.loaded,
    };
}

export default MapControls
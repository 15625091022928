import React, {useEffect, useMemo, useState} from 'react';
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import CardAccordion from "../CardAccordion";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import Validator from "../../controller/Validator";
import PropTypes from "prop-types";
import SiteDetails from "./SiteDetails";
import {Pagination} from 'react-bootstrap-controlled-pagination';
import useProject from "../../Hooks/useProject";

const Container = styled.div`
  width:100%;
  display:flex ;
  flex-direction: column;
  align-items: center;
  .accordion-container{
    width:100%;
    margin-bottom: 30px;
  }
`;
const StyledButton = styled(Button)`
    width:90%;
    margin: 0 5%;
    height: 67%;
`;
const DEFAULT_SITE_PARAMS = {
    displayName: '',
    location: {
        lat: 31.7683,
        lng: 35.2137
    },
    address: '',
    siteNumber: NaN,
    siteName: '',
    area: '',
};

function SitesManager({project,onSiteUpdated, onAddSite: addSite, onGoToSite: goToSite, onSiteDeleted: deleteSite}) {
    const {t} = useTranslation();
    const sortedProjectSites = JSON.parse(JSON.stringify(project.sites));
    const [newSiteDetails, setNewSiteDetails] = useState(DEFAULT_SITE_PARAMS);
    const [sites, setSites] = useState(sortedProjectSites);
    const [errors, setErrors] = useState([]);
    const [paginationNumber, setPagination] = useState(0);
    const [search, setSearch] = useState('');

    function addSiteButtonClick() {
        if (Validator.isNewSite(newSiteDetails)) {
            addSite(newSiteDetails);
            setSites([...sites, newSiteDetails]);
            setNewSiteDetails(DEFAULT_SITE_PARAMS);
        } else {
            throw Error('site Details isn\'t valid')
        }
    }

    useEffect(() => {
        setSites(sortedProjectSites)
    }, [project.sites]);

    function siteChange(site) {
        if (site._id) {
            if (site.displayName !== '') {
                setErrors(errors.filter(err => err !== site._id));
            }
            const siteIndex = sites.findIndex(site_i => site_i._id === site._id);
            const sitesClone = [...sites];
            sitesClone[siteIndex] = {...sites[siteIndex], ...site};
            setSites(sitesClone);
        } else {
            const message = 'no Site with Id Changed';
            throw Error(message);
        }
    }

    function updateSite(siteId) {
        const site = sites.find(site => site._id === siteId);
        onSiteUpdated(site);
    }

    const relevantSites = useMemo(
        () => sites
            .filter(site => [site.displayName, String(site.engineer), String(site.siteNumber)].some(text => text.includes(search)))
            .slice(paginationNumber * 10, paginationNumber * 10 + 10)
        , [paginationNumber, search,sites]
    );
    return <Container>
        {sites.length > 10 && <Form.Control type={'text'} placeholder={t('Search_site')} value={search}
                                            onChange={(ev) => setSearch(ev.target.value)}/>}
        <Accordion className={'accordion-container'}>
            {relevantSites && relevantSites.map((site, i) => (
                <CardAccordion
                    key={site._id}
                    eventKey={i}
                    title={site.displayName}>
                    <SiteDetails
                        vertical={true}
                        nameError={errors.includes(site._id)}
                        siteToManage={typeof goToSite === 'function' ? site : undefined}
                        onGoToSite={goToSite}
                        onSiteChange={siteChange}
                        onDelete={() => deleteSite(site, i)}
                        site={site}
                        button={<StyledButton onClick={() => {
                            if (site.displayName === '') {
                                setErrors([...errors, site._id])
                            } else {
                                updateSite(site._id)
                            }
                        }}>
                            {t('Edit_Site_Card')}
                        </StyledButton>}
                    />
                </CardAccordion>))}
            <CardAccordion
                eventKey={project ? project.sites.length : 0}
                key={project ? project.sites.length : 0}
                title={t('Add_Site_To_Project') + ' ' + t('(Optional)')}
                bold={true}
            >
                <SiteDetails
                    vertical={true}
                    nameError={errors.includes('new')}
                    site={newSiteDetails}
                    onSiteChange={(site) => {
                        if (site.displayName) setErrors(errors.filter(err => err !== 'new'));
                        setNewSiteDetails(site)
                    }}
                    button={<StyledButton onClick={() => {
                        if (newSiteDetails.displayName === '') {
                            setErrors([...errors, 'new']);
                        } else {
                            addSiteButtonClick();
                        }
                    }}>{t('Add_Site_Card')}</StyledButton>}
                />
            </CardAccordion>
        </Accordion>
        {sites.length > 10 &&
        <Pagination
            onSelect={setPagination}
            value={paginationNumber}
            end={Math.floor(sites.length / 10)}
        />
        }
    </Container>
}

SitesManager.propTypes = {
    onSiteUpdated: PropTypes.func.isRequired,
    onAddSite: PropTypes.func.isRequired,
    onGoToSite: PropTypes.func,
};

export default SitesManager;
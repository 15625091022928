import React, {useEffect, useRef} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import ProjectRow from "../ProjectRow";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import useProject from "../../Hooks/useProject";
import {closeDialog} from "../../store/actionCreators/general";
import {useFetchLoader} from "../../Hooks/common";


const Div = styled.div`
  &.modal-content{
    width:50vw !important; 
  }
`;


function ProjectList({projects, closeDialog,...props}) {
    const currentProject = useProject();
    const projectId = currentProject && currentProject._id;
    const {t} = useTranslation();
    const isFirstRun = useRef(true);
    useEffect (() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        closeDialog()
    },[projectId]);
    return (
        <Div className={'modal-content'}>
            <Modal.Header closeButton>
                <div className="section-title">
                    <h2>{t('Projects_Title')}</h2>
                </div>
            </Modal.Header>
            <div className="modal-body">
                {projects && projects.length > 0 ? projects.map(project => (
                    <ProjectRow
                        key={project._id}
                        project={project}
                    />
                )) : 'No Projects Available...'}
            </div>
        </Div>)
}


function mapStateToProps(reduxState) {
    return {projects: reduxState.map.projects};
}

export default connect(mapStateToProps,{closeDialog})(ProjectList);
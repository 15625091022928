import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import useProject, {useProjectParams} from "./useProject";
import EventEmitter from "../controller/EventEmitter";
import Constants from "../controller/Constants";
import {debounceCallback, ifFuncExec, zip} from "../controller/common";
import {setMapZoomCreator} from "../store/actionCreators/map";
import {useDispatch} from "react-redux";
import {useLocationStatsState, useMapZoom} from "./map";
import {useGoogleMap} from "@react-google-maps/api";
import {getBitRateFromBin} from "../controller/mapper";

function locationEqual(centerA, centerB) {
    const lat = ifFuncExec(centerA.lat) === ifFuncExec(centerB.lat);
    const lng = ifFuncExec(centerA.lng) === ifFuncExec(centerB.lng);
    return lat && lng;
}

function isBoundsEqual(boundsA, boundsB) {
    const flat = (bounds) => Object.values(bounds).flatMap((val2) => Object.values(val2));
    return zip(flat(boundsA), flat(boundsB)).every(([val1, val2]) => Object.is(val1, val2));
}

function mapChanged(currentMapData, oldMapData) {
    if (!oldMapData.bounds || !oldMapData.center || !oldMapData.zoom) return true;
    const zoomEqual = currentMapData.zoom === oldMapData.zoom;
    const centerEqual = locationEqual(currentMapData.center, oldMapData.center);
    const boundsEqual = isBoundsEqual(currentMapData.bounds, oldMapData.bounds);
    return ![zoomEqual, centerEqual, boundsEqual].every(_ => _);

}

function calcDist(loc1, loc2) {
    const {lat: lat1, lng: lng1} = loc1;
    const {lat: lat2, lng: lng2} = loc2;
    return window.google.maps.geometry.spherical.computeDistanceBetween(
        new window.google.maps.LatLng(lat1, lng1),
        new window.google.maps.LatLng(lat2, lng2)
    );

}

function getClosestBin(location, binsArray) {
    return binsArray.reduce(function (prev, curr) {
        return calcDist(location, prev.location) < calcDist(location, curr.location) ? prev : curr;
    })
}

function useDisplayMapDetails({binsArray}) {
    const [, setLocationStats] = useLocationStatsState();
    const map = useGoogleMap();
    const project = useProject();
    const projectParams = useProjectParams();
    const [center, setCenter] = useState(() => ({lat: map.getCenter().lat(), lng: map.getCenter().lng()}));
    const [bounds, setBounds] = useState(map.getBounds() || null);
    const points = useMemo(() => project.sites ? project.sites.map(site => (
        {lat: Number(site.location.lat), lng: Number(site.location.lng),}
    )) : [], [project]);
    const reduxZoom = useMapZoom();
    const mapDataRef = useRef({bounds, center, zoom: reduxZoom});
    const dispatch = useDispatch();
    const setMapZoom = (zoom) => dispatch(setMapZoomCreator(zoom));
    const updateMapState = useCallback(debounceCallback(function () {
        const mapCenter = {lat: map.getCenter().lat(), lng: map.getCenter().lng()};
        const mapBounds = map.getBounds();
        /*if (!centerEqual(mapCenter, center))*/
        const zoom = map.getZoom();
        const shouldSetState = mapChanged({bounds: mapBounds, center: mapCenter, zoom}, mapDataRef.current);
        if (shouldSetState) {
            setMapZoom(zoom);
            setCenter(mapCenter);
            /*if (!boundsEqual(mapBounds, bounds))*/
            setBounds(mapBounds);
            // google.maps.event.removeListener(listener);
        }
        // google.maps.event.removeListener(listener);
        mapDataRef.current.zoom = zoom;
        mapDataRef.current.center = mapCenter;
        mapDataRef.current.bounds = mapBounds;
    }, 400), [map, setMapZoom, setBounds, setCenter]);
    useEffect(() => {
        const id = EventEmitter.subscribe(Constants.events.SET_LOCATION_STATS, ({location, address}) => {
            if (binsArray.length === 0) return;
            const closestBin = getClosestBin(location, binsArray);
            const distance = calcDist(location, closestBin.location);
            const rssi = closestBin.rssi;
            const bitRate = getBitRateFromBin({location: closestBin.location, rssi}, closestBin.sites, projectParams);
            const isBitRateCapacity = projectParams.mapLayer === Constants.mapLayers.BIT_RATE_CAPACITY;
            const fixedNumber = (num, fixed) => Number(Number.parseFloat(num).toFixed(fixed || 2));
            setLocationStats({
                closestBin: closestBin,
                display: true,
                location,
                rssi: rssi,
                bitRate: isBitRateCapacity ? bitRate : null,
                address,
                distance: fixedNumber(distance, 1),
            })
        });
        return () => {
            EventEmitter.unsubscribe(Constants.events.SET_LOCATION_STATS, id);
        }
    }, [binsArray]);
    useEffect(() => {// position map when sites changed..
        if (points.length === 1) {
            map.setCenter(points[0]);
            map.setZoom(10)
        } else {
            const mapBounds = new window.google.maps.LatLngBounds();
            points.forEach(point => mapBounds.extend(point));
            map.fitBounds(mapBounds, {bottom: 200, right: 200, left: 200, top: 100});
        }
        const {google} = window;
        const id = google.maps.event.addListener(map, "idle", updateMapState);
        return () => {
            google.maps.event.removeListener(id);
        }
    }, [points]);
    return {center, bounds};
}

export default useDisplayMapDetails;
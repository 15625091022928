import {useCallback, useEffect, useRef, useState} from "react";
import {openDialog} from "../store/actionCreators/general";
import Constants from "../controller/Constants";
import {useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {UserApi} from "../controller/ApiManager/index";
import jwt from "jsonwebtoken";
import EventEmitter from "../controller/EventEmitter";
import {setUser} from "../store/actionCreators/user";
import {mapLoadedCreator} from "../store/actionCreators/map";

export function useAuth() {
    const dispatch = useDispatch();
    const setUserRedux = useCallback((payload) => {
        return dispatch(setUser(payload));
    }, [dispatch]);
    const openLoginDialog = useCallback(() => {
        return dispatch(openDialog(Constants.modals.LOGIN));
    }, [dispatch]);
    const location = useLocation();
    const userRedux = useSelector(state => state.user);
    const [error, setError] = useState(null);
    const [fetched, setFetched] = useState(false);
    const [fetching, setFetching] = useState(false);
    const fetchingRef = useRef(false);
    useEffect(() => {
        if (userRedux.isAuthenticated) {
            setError(null);
            setFetching(false);
            setFetched(true);
        } else {
            dispatch(mapLoadedCreator(false));
        }
    }, [userRedux.isAuthenticated]);

    const fetchRefreshToken = useCallback(async function (email, refreshToken) {
        try {
            setFetching(true);
            fetchingRef.current = true;
            const tokens = await UserApi.refreshToken({email, refreshToken});
            const {idToken, refreshToken: refreshRes} = tokens;
            if (typeof idToken !== 'string' || typeof refreshRes !== 'string') {
                console.log('token: ', tokens);
                throw Error('result from refresh token arent string!');
            }
            const decodedToken = jwt.decode(idToken);
            const {email: userEmail, 'cognito:groups': groups} = decodedToken;
            setUserRedux({user: {email: userEmail, groups}, idToken, refreshToken: refreshRes});
            setError(null);
        } catch (error) {
            console.error(error);
            setError(error);
            openLoginDialog();
        } finally {
            fetchingRef.current = false;
            setFetching(false);
            setFetched(true);
        }
    }, [setUserRedux, openLoginDialog]);

    useEffect(() => {
        const idTokenStored = localStorage.getItem(Constants.localStorage.ID_TOKEN);
        const refreshToken = localStorage.getItem(Constants.localStorage.REFRESH_TOKEN);
        if (Boolean(idTokenStored)) {
            const tokenDecoded = Boolean(idTokenStored) ? jwt.decode(idTokenStored.replace('Bearer ', '')) :
                {
                    email: '',
                    exp: Date.now() - 1000000000000000
                };
            // console.log('tokenDecoded:', tokenDecoded);
            const {exp, email, 'cognito:groups': groups} = tokenDecoded;
            const expirationDate = new Date(exp * 1000);
            const now = new Date(Date.now());
            const isExpired = Boolean(now.getTime() >= expirationDate.getTime());
            // console.log('expired at:', expirationDate);
            // console.log('now:', now);
            // console.log('isExpired:', isExpired);
            if (!isExpired) {
                setUserRedux({user: {email, groups}, idToken: idTokenStored, refreshToken});
                setFetching(false);
                setFetched(true);
            } else if (isExpired && Boolean(idTokenStored) && Boolean(refreshToken)) {
                fetchRefreshToken(email, refreshToken);
            }
        } else {
            openLoginDialog();
            setFetching(false);
            setFetched(true);
            setError(Error('require login'));
        }
    }, [location.pathname, fetchRefreshToken, setUserRedux, openLoginDialog]);
    useEffect(() => {
        const eventId = EventEmitter.subscribe(Constants.events.REFRESH_USER_TOKEN, (data) => {
            const refreshToken = localStorage.getItem(Constants.localStorage.REFRESH_TOKEN);
            const idToken = localStorage.getItem(Constants.localStorage.ID_TOKEN);
            const decodedToken = jwt.decode(idToken);
            const {email} = decodedToken;
            if (refreshToken && !fetchingRef.current) {
                setTimeout(() => fetchRefreshToken(email, refreshToken).then(console.log), 10);
            }
            if (fetchingRef.current) {
                // debugger;
            }
        });
        return () => {
            EventEmitter.unsubscribe(Constants.events.REFRESH_USER_TOKEN, eventId);
        }
    }, [fetchRefreshToken]);
    return {error, fetched, fetching}
}
import {useEffect, useState} from "react";
import EventEmitter from "../controller/EventEmitter";
import Constants from "../controller/Constants";
import Api from "../controller/ApiManager";
import useProject from "./useProject";

const {events, mapLayerValues} = Constants;
export default function useAsyncLoading() {
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState({steps: 0, end: 0});
    const project = useProject();
    useEffect(() => {
        const eventId = EventEmitter.subscribe(events.REARRANGE_ALL_LAYERS, ({exceptMapLayer}) => {
                const relevantLayers = mapLayerValues.filter(layer => layer !== exceptMapLayer);
                setLoading(true);
                setProgress({steps: 1, end: 6});
                async function task() {
                    try {
                        const tasks = relevantLayers.map(async layer => {
                            await Api.Project.clusterProject(project._id, layer);
                            setProgress(last => ({...last, steps: last.steps + 1}))
                        });
                        await Promise.all(tasks);
                    } catch (e) {
                        console.error(e);
                    } finally {
                        setLoading(false);
                    }
                }
                task().then();
            }
        );
        return () => {
            EventEmitter.unsubscribe(events.REARRANGE_ALL_LAYERS, eventId);
        }
    });
    return {loading, progress};
}
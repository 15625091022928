import urls from "../configuations/endpoints";

function sortSmartTypeString(a, b) {
    const orderList = [
        'TYPE_1X1',
        'TYPE_2X2',
        'TYPE_4X4',
        'TYPE_8X8',
        'TYPE_8X8_STEERED',
        'TYPE_16X16',
        'TYPE_32X32',
        'TYPE_64X64_STEERED',
    ];
    const aNum = orderList.indexOf(a);
    const bNum = orderList.indexOf(b);
    return aNum - bNum;
}

const smartTypes = {
    TYPE_1X1: 1,
    TYPE_2X2: 2,
    TYPE_4X4: 4,
    TYPE_8X8: 8,
    TYPE_8X8_STEERED: 8,
    TYPE_16X16: 16,
    TYPE_32X32: 32,
    TYPE_64X64_STEERED: 64,
};
const Constants = {
    TRANSLATION_URL: urls.TRANSLATION_URL,
    AUTH_URL: urls.AUTH_URL,
    REST_URL: urls.REST_URL,
    //^^^^^^^^^prototypes for code completions^^^^^^^^^^
    //^^^^^^^^^will be override by urls^^^^^^^^^^
    ...urls,
    GOOGLE_MAPS_API_KEY: 'AIzaSyCcw1r0WeZoTGxZDNR8ryrhHf37okOpp_U',
    EMAIL_URL: 'https://aut4pawf7k.execute-api.eu-west-1.amazonaws.com/dev/',
    JWT_TOKEN_ID: 'token_id',
    JWT_REFRESH_TOKEN: 'refresh_token',
    JWT_ACCESS_TOKEN: 'access_token',
    JWT_EMAIL: 'user_email',
    LOGIN_SUFFIX_URL: 'login',
    REGISTER_SUFFIX_URL: 'register',
    REFRESH_TOKEN_SUFFIX_URL: 'refreshToken',
    EARTH_RADIUS: 6372.797,
    LAT_LNG_TO_METERS: 6372.797 * (Math.PI / 180),
    METERS_TO_LAT_LNG: 1 / 6372.797 * (Math.PI / 180),
    STAGE: process.env.REACT_APP_STAGE,
    modals: {
        REGISTER: 'REGISTER',
        LOGIN: 'LOGIN',
        PARAMETERS: 'PARAMETERS',
        ADD_PROJECT: 'ADD_PROJECT',
        TABLE: 'TABLE',
        PROJECT_LIST: 'PROJECT_LIST',
        EDIT_PROJECT: 'EDIT_PROJECT',
        EDIT_SITE: 'EDIT_SITE',
        CHOSE_2_SITES: 'CHOSE_2_SITES',
        CLUSTER: 'CLUSTER',
        CLUSTER_ALERT: 'CLUSTER_ALERT',
        DISPLAY_COVERAGE: 'DISPLAY_COVERAGE',
        TECH_CONVERSION:'TECH_CONVERSION',
    },
    mapTypes: {
        BEST_SERVER: 'BEST_SERVER',
        C2I: 'C2I',
    },
    mapStates: {
        SITES_COVERAGE: 'SITES_COVERAGE',
        AREA_SELECTION: 'AREA_SELECTION',
    },
    events: {
        UPDATE_PROJECT: 'UPDATE_PROJECT',
        SET_CENTER: 'SET_CENTER',
        CHOSE_2_SITES: 'CHOSE_2_SITES',
        REFRESH_USER_TOKEN: 'REFRESH_USER_TOKEN',
        DELETE_POLYGON: 'DELETE_POLYGON',
        RESET_POLYGON: 'RESET_POLYGON',
        CENTER_CHANGED: 'CENTER_CHANGED',
        REARRANGE_ALL_LAYERS: 'REARRANGE_ALL_LAYERS',
        SET_LOCATION_STATS: 'SET_LOCATION_STATS',
    },
    localStorage: {
        REFRESH_TOKEN: 'refreshToken',
        ID_TOKEN: 'idToken',
        ACCESS_TOKEN: 'accessToken',
    },
    errors: {
        NO_1X1_MODEL: 'sector is missing vertical(default) antenna model',
        FUNCTION_DEPRECATED: 'function is deprecated!',
        TRY_AGAIN: 'refresh token has dispatched, please try again you last operation.',
        UNAUTHORIZED: 'user not authorized',
        NO_PROJECT_ID: 'no providerId provided!',
        NO_BINS: 'No BinsPlacements Field in Sector.',
    },
    smartTypeStrings: [...Object.keys(smartTypes)].sort(sortSmartTypeString),
    antennaModelStrings: [...Object.keys(smartTypes)].sort(sortSmartTypeString),
    smartTypes,
    technologies: ['3G-UMTS-FDD', '4G-LTE-FDD', '5G-TDD'],
    frequencyBands: [3600, 2600, 2100, 1800],
    channelBWs: [20, 40, 60, 100],
    polarizationTypes: ['Vertical', 'Horizontal', 'Circular', 'Dual Slant 45deg'],
    mapLayerValues: [
        'RSSI - Coverage Optimized',
        'RSSI - Capacity Optimized',
        'MCS - Coverage Optimized',
        'MCS - Capacity Optimized',
        'Bit Rate - Coverage Optimized',
        'Bit Rate - Capacity Optimized',
        'RSRP - Coverage Optimized',
        'RSRP - Capacity Optimized',
        'RSRQ - Coverage Optimized',
        'RSRQ - Capacity Optimized',
    ],
    mapLayers: {
        RSSI_COVERAGE: 'RSSI - Coverage Optimized',
        RSSI_CAPACITY: 'RSSI - Capacity Optimized',
        MCS_COVERAGE: 'MCS - Coverage Optimized',
        MCS_CAPACITY: 'MCS - Capacity Optimized',
        BIT_RATE_COVERAGE: 'Bit Rate - Coverage Optimized',
        BIT_RATE_CAPACITY: 'Bit Rate - Capacity Optimized',
        RSRP_COVERAGE: 'RSRP - Coverage Optimized',
        RSRP_CAPACITY: 'RSRP - Capacity Optimized',
        RSRQ_COVERAGE: 'RSRQ - Coverage Optimized',
        RSRQ_CAPACITY: 'RSRQ - Capacity Optimized',
    },
    userGroups: {
        MANAGER: 'manager',
        ADMIN: 'admin'
    },
    antennaConfigs: ['Model-A', 'Model-B', 'Steered',],
    siteModes: {predesign: 'Predesign', postdesign: 'Postdesign'},
};
export default Constants;
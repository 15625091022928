import React, {useRef} from 'react';
import Button from "react-bootstrap/Button";

function FileUploaderBtn({onFileChange, label}) {
  const inputRef = useRef(null);

  function handleUpload(){
    inputRef.current?.click();
  };

  function handlOnChange(){
    inputRef.current?.files &&
      onFileChange(inputRef.current.files[0]);
  };
  return (
    <div >
      <input
        ref={inputRef}
        onChange={handlOnChange}
        type="file"
        className="d-none"
        accept=".json"
      />
      <Button
        id="uploadFile"
        onClick={handleUpload}
      >
        {label}
      </Button>
    </div>
  );
}

export default FileUploaderBtn;
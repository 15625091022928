import React from "react";
import styled from 'styled-components';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {setMapState} from "../../store/actionCreators/map";
import Constants from "../../controller/Constants";
import EventEmitter from "../../controller/EventEmitter";
const Div = styled.div`
  position: absolute;
  top: 10px;
  right: 236px;
`;
const ControlButton = styled(Button)`
    transition:0s;
    background-color: #ffffff ;
    color: rgba(0,0,0,0.7);
    &.btn.btn-lg{
        padding: 3px 7px;
        border-radius: 0;
    }
    i{
      transition:0s;
      font-weight: bold;
    }
    &:hover{
        color: rgba(0,0,0,1);
    }
`;

function SelectAreaButton({mapState,setMapState}) {
    function selectAreaState(){
        const {AREA_SELECTION,SITES_COVERAGE} = Constants.mapStates;
        if (mapState === AREA_SELECTION){
            EventEmitter.dispatch(Constants.events.DELETE_POLYGON);
        }
        setMapState( mapState === AREA_SELECTION?SITES_COVERAGE:AREA_SELECTION);
    }
    return <Div>
        <ButtonGroup aria-label="Basic example" title={'draw a polygon to see data inside it'}>
            <ControlButton onClick={selectAreaState} variant="" size="lg"><i className="fa fa-map-o"/> </ControlButton>
            {/*<ControlButton variant="" size="lg">Middle</ControlButton>*/}
            {/*<ControlButton variant="" size="lg">Right</ControlButton>*/}
        </ButtonGroup>
    </Div>
}

SelectAreaButton.propTypes = {
    // onSelectArea: PropTypes.func.isRequired
};
function mapStateToProps(reduxState){
    return {
        mapState:reduxState.map.mapState,
    }
}
export default connect(mapStateToProps,{setMapState})(SelectAreaButton);
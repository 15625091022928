import Constants from "./Constants.js";
import {sectorCompareValues} from "./models/sector";
import {typeCheck} from "./common";


export function sortSmartTypeString(a, b) {
    const orderList = [
        'TYPE_1X1',
        'TYPE_2X2',
        'TYPE_4X4',
        'TYPE_8X8',
        'TYPE_8X8_STEERED',
        'TYPE_16X16',
        'TYPE_32X32',
        'TYPE_64X64_STEERED',
    ];
    const aNum = orderList.indexOf(a);
    const bNum = orderList.indexOf(b);
    return aNum - bNum;
}


export function sortByName(name, name2) {
    if (name < name2) {
        return -1;
    }
    if (name > name2) {
        return 1;
    }
    return 0;
}

export function constructHeightsString(project, displayedSectors) {
    return `${project._id}:${
        displayedSectors.sort(function (a, b) {
            if (a.siteId < b.siteId) {
                return -1;
            }
            if (a.siteId > b.siteId) {
                return 1;
            }
            return 0;
        }).map(heightObject => `${heightObject.siteId},${heightObject.sectorId},${heightObject.antennaModel}`).join(';')
        }`;
}

// export function addSectorToDisplay(localDisplayedSectors, globalDisplayedSectors, displayedSectorsActions) {
//     const {updateDisplayedSector, addDisplayedSectors, removeDisplayedSectors, cleanDisplayedSectors} = displayedSectorsActions;
//     let globalSectorsCopy = [...globalDisplayedSectors];
//     if (localDisplayedSectors.length < 1) return;
//     for (const localDisplayedSector of localDisplayedSectors) {
//         if (!localDisplayedSector.selected) {
//             globalSectorsCopy = removeDisplayedSector(localDisplayedSector);
//         } else if (displayedSectorInList(localDisplayedSector)) {
//             if (displayedSectorChanged(localDisplayedSector)) {
//                 globalSectorsCopy = updateDisplayedSector(localDisplayedSector);
//             }
//         } else {
//             globalSectorsCopy = addDisplayedSector(localDisplayedSector);
//         }
//     }
//     setDisplayedSectors(globalSectorsCopy);
//
//     function removeDisplayedSector(displayedSector) {
//         return globalSectorsCopy.filter(obj => obj.siteId !== displayedSector.siteId || obj.sectorId !== displayedSector.sectorId);
//     }
//
//     function displayedSectorChanged(displayedSector) {
//         const height = globalSectorsCopy.find(obj => obj.siteId === displayedSector.siteId && obj.sectorId === displayedSector.sectorId);
//         return height.antennaModel !== displayedSector.antennaModel;
//     }
//
//     function displayedSectorInList(displayedSector) {
//         return globalSectorsCopy.some(obj => obj.siteId === displayedSector.siteId && obj.sectorId === displayedSector.sectorId);
//     }
//
//     // function updateDisplayedSector(displayedSector) {
//     //     return globalSectorsCopy.map(obj => {
//     //         if (obj.siteId === displayedSector.siteId && obj.sectorId === displayedSector.sectorId)
//     //             obj.antennaModel = displayedSector.antennaModel;
//     //         return obj;
//     //     })
//     // }
//
//     function addDisplayedSector(displayedSector) {
//         const newDisplayedSector = {
//             siteId: displayedSector.siteId,
//             sectorId: displayedSector.sectorId,
//             antennaModel: displayedSector.antennaModel
//         };
//         globalSectorsCopy = [...globalSectorsCopy, newDisplayedSector];
//         return globalSectorsCopy;
//     }
// }


export function isSameLocation(point1, point2) {
    return (Number(point1.lat) === Number(point2.lat) && Number(point1.lng) === Number(point2.lng))
}

export function isSitesEqual(siteA, siteB) {
    return siteA._id === siteB._id;
}

export function isSiteEqualToHeight(site, heightObj) {
    return site._id === heightObj.site;
}

export function isPointInPolygon(point, polygonPath) {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
    const x = point[0], y = point[1];
    let inside = false;
    for (let i = 0, j = polygonPath.length - 1; i < polygonPath.length; j = i++) {
        const xi = polygonPath[i][0], yi = polygonPath[i][1];
        const xj = polygonPath[j][0], yj = polygonPath[j][1];
        // eslint-disable-next-line
        const intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect)
            inside = !inside;
    }
    return inside;
}

export function isLatLngInBounds(location, bounds) {
    return bounds.contains(location);
}

export function siteDetailsEqual(aSite, bSite) {
    const name = aSite.displayName === bSite.displayName;
    const location = aSite.location.lng === bSite.location.lng && aSite.location.lat === bSite.location.lat;
    const address = aSite.address === bSite.address;
    const siteNumber = aSite.siteNumber === bSite.siteNumber;
    const siteName = aSite.siteName === bSite.siteName;
    const area = aSite.area === bSite.area;
    return [name, location, address, siteNumber, siteName, area].every(Boolean);
}


export function compareAntennaPrices(aAntennaPrices, bAtennaPrices) {
    const {smartTypeStrings} = Constants;
    return smartTypeStrings.every(name => aAntennaPrices[name] === bAtennaPrices[name]);
}

export function compareSectors(aSector, bSector) {
    const compareArrays = (array1, array2) => array1.length === array2.length && array1.every((value, index) => value === array2[index]);
    const incSort = (a, b) => a - b;
    const smartTypesEq = compareArrays(aSector.antennaModels.sort(incSort), bSector.antennaModels.sort(incSort));
    const antennaPricesEq = compareAntennaPrices(aSector.antennaPrices, bSector.antennaPrices);
    const values = sectorCompareValues.reduce((acc, {field, type}) => ({
        ...acc,
        [field]: {
            bool: aSector[field] === bSector[field] && typeCheck(aSector[field], type),
            a: aSector[field],
            b: bSector[field],
            type,
            typeCheck: typeCheck(aSector[field], type)
        }
    }), {});
    const bools = Object.values(values).map(({bool}) => bool);
    return [...bools, smartTypesEq, antennaPricesEq].every(bool => bool);
}

export function findSectorId(project, siteId, heightMeters, azimuthDegrees = null) {
    return project.sites.find(site => site._id === siteId).preDesign.sectors
        .find(({height, azimuth}) => azimuthDegrees === azimuth && height === heightMeters)._id;
}

export function isParamsClusteringFieldsChanged(projectParams, newParams) {
    const fields = ['binReliability', 'binResolution', 'samplesReliability'];
    const {devParams} = projectParams;
    const {devParams: newDevParams} = newParams;
    return fields.some(field => devParams[field] !== newDevParams[field])
}

export function findSite(project,siteId){
    return project.sites.find(siteI => siteI._id === siteId);
}

export function findSector(site,sectorId){
    const sectors = getSiteSectors(site);
    return sectors.find(sectorI => sectorI._id === sectorId);
}

export function findSectorOfProject(project,sectorId){
    const sectors = project.sites.flatMap(site=>getSiteSectors(site));
    return sectors.find(sectorI => sectorI._id === sectorId);
}


export function getSiteSectors(site) {
    return [...site.preDesign.sectors, ...site.postDesign.sectors];
}

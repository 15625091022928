import validate from "validate.js";
import Constants from "./Constants";

const {errors} = Constants;

export function isLight(color, lightness = 180) {
    var red, green, blue, hsp;
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        red = color[1];
        green = color[2];
        blue = color[3];
    }
    else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace(
            color.length < 5 && /./g, '$&$&'));
        // eslint-disable-next-line
        red = color >> 16;
        // eslint-disable-next-line
        green = color >> 8 & 255;
        // eslint-disable-next-line
        blue = color & 255;
    }
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
        0.299 * (red * red) +
        0.587 * (green * green) +
        0.114 * (blue * blue)
    );
    // Using the HSP value, determine whether the color is light or dark
    return hsp > lightness;
}

export function ifFuncExec(obj) {
    if (typeof obj === 'function') {
        return obj();
    }
    return obj;
}

export function ifShow(booleanValue) {
    return Boolean(booleanValue || booleanValue === 0)
}

export function typeCheck(obj, type, enums) {
    const enumCheck = () => enums ? enums.includes(obj) : true;
    if (!enumCheck()) return false;
    if (obj === null) return true;
    switch (type) {
        case String:
            return validate.isString(obj);
        case Number:
            return validate.isNumber(obj);
        default:
            throw Error('unsporrted type: ' + type);
    }
}

export function deprecationError() {
    throw Error(errors.FUNCTION_DEPRECATED);
}

export function debounceCallback(fn, time) {
    let timeout;
    return function () {
        const args = arguments;
        const functionCall = () => fn.apply(this, args);
        clearTimeout(timeout);
        timeout = setTimeout(functionCall, time);
    }
}

export function zip(a, b) {
    return Array.from(Array(Math.max(b.length, a.length)), (_, i) => [a[i], b[i]]);
}

export function deepCompare() {
    var i, l, leftChain, rightChain;

    function compare2Objects(x, y) {
        let p;
        if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') return true;
        if (x === y) return true;
        if ((typeof x === 'function' && typeof y === 'function') ||
            (x instanceof Date && y instanceof Date) ||
            (x instanceof RegExp && y instanceof RegExp) ||
            (x instanceof String && y instanceof String) ||
            (x instanceof Number && y instanceof Number)) {
            return x.toString() === y.toString();
        }
        if (!(x instanceof Object && y instanceof Object)) return false;
        if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) return false;
        if (x.constructor !== y.constructor) return false;
        if (x.prototype !== y.prototype) return false;
        if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) return false;
        for (p in y) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) return false;
            else if (typeof y[p] !== typeof x[p]) return false;
        }
        for (p in x) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) return false;
            else if (typeof y[p] !== typeof x[p]) return false;

            switch (typeof (x[p])) {
                case 'object':
                case 'function':
                    leftChain.push(x);
                    rightChain.push(y);
                    if (!compare2Objects(x[p], y[p])) return false;
                    leftChain.pop();
                    rightChain.pop();
                    break;
                default:
                    if (x[p] !== y[p]) return false;
                    break;
            }
        }
        return true;
    }

    if (arguments.length < 1) throw Error('missing functin arguments, al least 2 should be supplied');
    for (i = 1, l = arguments.length; i < l; i++) {
        leftChain = []; //Todo: this can be cached
        rightChain = [];
        if (!compare2Objects(arguments[0], arguments[i])) {
            return false;
        }
    }
    return true;
}

export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

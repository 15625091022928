import React, {useRef} from 'react';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import {setMapState} from "../../store/actionCreators/map";
import Constants from "../../controller/Constants";
import {connect} from 'react-redux';
import EventEmitter from "../../controller/EventEmitter";
import {useSelector ,useDispatch} from 'react-redux';
import ConfigFileManager from "../Common/ConfigFileManager"
import {setPolygonPath} from "../../store/actionCreators/map"
import PolygonFileManager from '../Common/PolygonFileManager';


const Div = styled.div`
  position: absolute;
  bottom: 70px;
  right: 50%;
  transform: translateX(50%);
  text-align: center;

`;

const ControlButton = styled(Button)`
    transition:0s;
    background-color: #ffffff;
    color: rgba(0,0,0,0.7);
    font-size: 20px;
    i{
       transition:0s;
      font-weight: bold;
    }
    &:hover{
        color: rgba(0,0,0,1);
    }
`;

function AreaSelectionControls({setMapState}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const polygonPath = useSelector(state => state.map.polygonPath)
    const configFileManagerRef = useRef(null);
    const isMosheChanged = t('Cancel_asp') !== 'Cancel_asp' || t('Delete_asp') !== 'Delete_asp' || t('Reset_asp') !== 'Reset_asp' || t('Save_asp') !== 'Save_asp' ;
    function savePolygon(){
        // todo: future upsdate... add undo changes. and function should be acutally saving the state.
        setMapState(Constants.mapStates.SITES_COVERAGE);
    }
    function deletePolygon(){
        EventEmitter.dispatch(Constants.events.DELETE_POLYGON);
        setMapState(Constants.mapStates.SITES_COVERAGE);
    }
    function resetPolygon(){
        EventEmitter.dispatch(Constants.events.RESET_POLYGON);
    }
    // function exportPolygon(action){
    //     Array.from(configFileManagerRef.current.getElementsByTagName("Button")).find(element => element.id === action).click();
    // }
    return <Div>
        <ButtonGroup aria-label="Basic example">
            {/*<ControlButton onClick={()=>{}} variant="primary" size="lg">{t('Cancel_asp')}</ControlButton>*/}
            <ControlButton onClick={savePolygon} variant="primary" size="lg">{t('Save_asp')}</ControlButton>
            <ControlButton onClick={resetPolygon} variant="primary" size="lg">{t('Reset_asp')}</ControlButton>
            <ControlButton onClick={deletePolygon} variant="primary" size="lg">{t('Delete_asp')}</ControlButton>
            <ControlButton onClick={()=>configFileManagerRef.current.downloadFile()} variant="primary" size="lg">{t('Export_asp')}</ControlButton>
            <ControlButton onClick={()=>configFileManagerRef.current.uploadFile()} variant="primary" size="lg">{t('Upload_asp')}</ControlButton>
            <PolygonFileManager ref={configFileManagerRef} />
        </ButtonGroup>
    </Div>
}
function mapStateToProps(reduxState){
    return {}
}
export default connect(mapStateToProps,{setMapState})(AreaSelectionControls);
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Constants from "../controller/Constants";
import Modal from "react-bootstrap/Modal";
import {SET_CURRENT_DIALOG} from "../store/actionTypes";
import {
    Register,
    AddProject,
    ProjectList,
    EditProject,
    EditSite,
    Parameters,
    DisplayCoverage,
    Choose2Sites,
    Cluster,
    ClusterAlert,
    Login,
} from "./Dialogs";
import styled from "styled-components";
import {useFetchLoader} from "../Hooks/common";
import {DialogAlertProvider} from "../contexts/DialogAlert";
import TechConversion from "./Dialogs/TechConversion";

const StyledModal = styled(Modal)`
    &::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
`;


function DialogGenerator({}) {
    const dispatch = useDispatch();
    const {currentDialog} = useSelector(MapStateToProps);
    const [closing, setClosing] = useState(false);
    const [previousDialogs, setPreviousDialogs] = useState([]);
    const [fetching, setFetching] = useFetchLoader();

    function closeDialogs() {
        dispatch({
            type: SET_CURRENT_DIALOG,
            dialog: null,
        });
        setClosing(false);
        setPreviousDialogs([]);
    }

    useEffect(() => {
        setPreviousDialogs([...previousDialogs, currentDialog]);
        if (currentDialog === null && fetching) setFetching(false)
    }, [currentDialog]);

    function backDialog() {
        dispatch({
            type: SET_CURRENT_DIALOG,
            dialog: previousDialogs.slice(-2)[0],
        });
        setPreviousDialogs([...previousDialogs].splice(-1, 1))
    }

    function onFetchStart() {
        setFetching(true);
    }

    function onFetchEnd() {
        setFetching(false);
    }

    function setFetchingProp(flag) {
        setFetching(flag);
    }

    const currentDialogComponent = (() => {
        switch (currentDialog) {
            case Constants.modals.REGISTER:
                return <Register key={Constants.modals.REGISTER}/>;
            case Constants.modals.ADD_PROJECT:
                return <AddProject key={Constants.modals.ADD_PROJECT}
                                   onClose={closeDialogs}
                                   onBack={backDialog}
                />;
            case Constants.modals.PROJECT_LIST:
                return <ProjectList key={Constants.modals.PROJECT_LIST}/>;
            case Constants.modals.EDIT_PROJECT:
                return <EditProject
                    key={Constants.modals.EDIT_PROJECT}/>;
            case Constants.modals.EDIT_SITE:
                return <EditSite
                    onBack={backDialog}
                    key={Constants.modals.EDIT_SITE}/>;
            case Constants.modals.PARAMETERS:
                return <Parameters
                    key={Constants.modals.PARAMETERS}/>;
            case Constants.modals.CHOSE_2_SITES:
                return Choose2Sites;
            case Constants.modals.CLUSTER:
                return <Cluster
                    key={Constants.modals.CLUSTER}/>;
            case Constants.modals.CLUSTER_ALERT:
                return <ClusterAlert
                    key={Constants.modals.CLUSTER}/>;
            case Constants.modals.LOGIN:
                return <Login
                    key={Constants.modals.LOGIN}/>;
            case Constants.modals.DISPLAY_COVERAGE:
                return <DisplayCoverage
                    key={Constants.modals.DISPLAY_COVERAGE}
                    onClose={closeDialogs}/>;
            case Constants.modals.TECH_CONVERSION:
                return <TechConversion
                    key={Constants.modals.DISPLAY_COVERAGE}
                    onClose={closeDialogs}/>;
            default:
                return null;
        }
    })();
    const DialogObject = currentDialogComponent && React.cloneElement(currentDialogComponent,
        {onFetchStart, onFetchEnd, setFetching: setFetchingProp});
    return (<DialogAlertProvider>
        <StyledModal className={'dialog-generator'}
                     show={!closing && Boolean(currentDialog)}
                     onHide={() => {
                         setClosing(true);
                     }}
                     onExited={closeDialogs}
                     scrollable
                     enforceFocus={false}
        >
            {DialogObject}
        </StyledModal>
    </DialogAlertProvider>);
}

function MapStateToProps(reduxState) {
    return {currentDialog: reduxState.general.currentDialog};
}

export default DialogGenerator;
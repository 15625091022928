import React from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import BackButton from "../StyledComponents/BackButton";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import {openDialog} from "../../store/actionCreators/general";
import Constants from "../../controller/Constants";


const ModalContent = styled.div`
        width:20vw;
`;
const SubmitButton = styled(Button)`
    &.btn[type=submit]{
      margin-top: 20px !important;
    }
`;
const P = styled.h3`
     font-weight: normal;
     color: #000;
`;

function ClusterAlert({onBack, addToast, setProject, openDialog}) {
    const {t} = useTranslation();
    return (
        <ModalContent>
            <Modal.Header closeButton>
                <div className="section-title">
                    <h2>{t('Alert') + ' !'}</h2>
                    {typeof onBack === 'function' &&
                    <BackButton className='btn btn-outline-light btn-rounded' onClick={onBack}/>}
                </div>
            </Modal.Header>
            <Modal.Body>
                <P>
                    there is no cluster to this project, to make bins available please go to {t('Rearrange_Bins')} to
                    set the project details!
                </P>
                <SubmitButton
                    onClick={() => openDialog(Constants.modals.CLUSTER)}
                    variant={'primary'}>
                    {t('Go_To_Rearrange_Bins')}
                </SubmitButton>
            </Modal.Body>
        </ModalContent>
    )
}



export default connect(()=>({}), {openDialog})(ClusterAlert);
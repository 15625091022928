import Form from "react-bootstrap/Form";
import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";

const FileUpload = styled.div`
       &.file-upload{
            margin: 0;
       }
       &.file-upload.disable input{
          background-color: rgba(187, 100, 100, 0.13);
          border: 2px dashed rgba(193, 19, 19, 0.29);
       }
       &.file-upload.disable input:hover{
          background-color: rgba(187, 100, 100, 0.13);
          border: 2px dashed rgba(193, 19, 19, 0.29);
       }
       &.file-upload.error input{
          background-color: rgba(203, 55, 55, 0.19);
          border: 2px dashed rgba(185, 20, 20, 0.58);
       }
       &.file-upload.error input:hover{
          background-color: rgba(203, 55, 55, 0.19);
          border: 2px dashed rgba(185, 20, 20, 0.58);
       }
       &.file-upload.error input:focus, &.file-upload.disable input:focus{
          outline: none;
       }
       &.file-upload.error span{
          color:rgb(185,20,20)
       }
`;
const FileLocalFeedback = styled.div`
  position:absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 40%;
  padding-left: 20px;
  padding-top: 10px;
`;
const FileIconContainer = styled.div`
    padding: 5px 20px;
`;

const I = styled.i`
    font-size: 25px;
`;

function FileInput({disable, onFileChange, file, multi, formats}) {
    const fileInputRef = useRef(null);
    const formatsString = typeof formats === 'string' ? formats : formats instanceof Array ? formats.join(',') : '';
    const [error, setError] = useState(false);

    function fileChange(ev) {
        if (disable) {
            setError(true);
            return
        }
        if (multi) {
            return onFileChange(ev.target.files);
        }
        return onFileChange(ev.target.files[0])
    }

    useEffect(() => {
        fileInputRef.current.accept = formatsString
    }, [fileInputRef.current]);
    
    function handleClick(ev){
        if (disable){
            ev.preventDefault();
            setError(true);
        }
    }

    function handleBlur(){
        setError(false);

    }

    return (<FileUpload className={"file-upload " + (error ? 'error' : (disable ? "disable" : ''))}>
        <div className="MultiFile-wrap" id="MultiFile1">
            <Form.Control
                onBlur={handleBlur}
                onClick={(handleClick)}
                ref={fileInputRef}
                isValid={true}
                as={'input'}
                type="file"
                className="file-upload-input with-preview MultiFile-applied"
                multiple=""
                title="Click to add files"
                maxLength="10"
                accept="mtd"
                onChange={fileChange}
            />
            {file &&
            <FileLocalFeedback>
                <FileIconContainer>
                    <I className="fa fa-file-o"/>
                </FileIconContainer>
                <small>{file.name}</small>
            </FileLocalFeedback>
            }
        </div>
        {!file &&
        (!disable ?
            <span>Click or drag drive test file here</span> :
            <span>Please Add Height First</span>)
        }
    </FileUpload>)
}

FileInput.propTypes = {
    fileChange: PropTypes.func,
    file: PropTypes.object,
    fileInputRef: PropTypes.object,
    formats: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ])
};
export default FileInput;
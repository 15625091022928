import React, {useRef, useState} from "react";
import {calcRadiusMultByZoom, colorMapper, getRssiLevels,} from "../../controller/mapper";
import {Circle, Polyline, Rectangle} from '@react-google-maps/api'
import BinDetails from "./BinDetails";
import {useProjectParams} from "../../Hooks/useProject";
import {useDebounce, usePredictionState} from "../../Hooks/common";
import Constants from "../../controller/Constants";
import {useSelector} from "react-redux";

const {METERS_TO_LAT_LNG} = Constants;

function generateBounds(center, radius) {
    const calc = (field, mult = 1) => center[field] + mult * METERS_TO_LAT_LNG * radius / (field === 'lat' ? 1 : 1);
    return {
        east: calc('lng'),
        north: calc('lat'),
        south: calc('lat', -1),
        west: calc('lng', -1)
    }
}

function Bin({fromSites, details}) {
    const {lines, mapType, zoomMultiplier, highlightOpacity} = useSelector(mapStateToProps);
    const [forcedLine, setForcedLine] = useState(false);
    const [tooltip, setTooltip] = useState(false);
    const projectParams = useProjectParams();
    const [predictionState] = usePredictionState();
    const debouncePredictionState = useDebounce(predictionState, 500);
    const {devParams: {binResolution: binDiameter}, rssiThreshold} = projectParams;
    const locationObj = {lat: Number(details.location.lat), lng: Number(details.location.lng),};
    const signalLevels = getRssiLevels(mapType, projectParams.mapLayer, projectParams.channelBW, rssiThreshold);
    const radius = /*binDiameter > 50 ? 50 / 2 :*/ binDiameter / 2;
    const binColor = colorMapper(details.signal, signalLevels);
    const circleRef = useRef(null);
    const rectangleRef = useRef(null);

    function mouseEnter() {
        if (!tooltip) {
            setTooltip(true);
        }
    }

    function mouseOut() {
        setTooltip(false);
    }

    // debounde equal to the state let the component to unmount and then change the component ( there was an error there...).
    return debouncePredictionState === predictionState && <>
        {predictionState ?
            <Rectangle
                onLoad={(rectangle) => {
                    if (circleRef.current) {
                        circleRef.current.setMap(null);
                        circleRef.current = null;
                    }
                    rectangleRef.current = rectangle;
                }}
                bounds={generateBounds(locationObj, binDiameter * 1.642)}
                onMouseMove={mouseEnter}
                onMouseOut={mouseOut}
                options={{
                    // strokeColor: '#FF0000',
                    strokeOpacity: 0,
                    strokeWeight: 2,
                    fillColor: binColor,
                    fillOpacity: highlightOpacity || 0.6,
                    clickable: true,
                    draggable: false,
                    editable: false,
                    visible: true,
                    zIndex: 1000 + (details.signal),
                }}
                onClick={() => {
                    setForcedLine(!forcedLine);
                }}
            />
            :
            <Circle
                onLoad={(circle) => {
                    circleRef.current = circle;
                }}
                center={locationObj}
                radius={radius * zoomMultiplier}
                onMouseMove={mouseEnter}
                onMouseOut={mouseOut}
                options={{
                    strokeColor: '#FF0000',
                    strokeOpacity: 0,
                    strokeWeight: 2,
                    fillColor: binColor,
                    fillOpacity: 1,
                    clickable: true,
                    draggable: false,
                    editable: false,
                    visible: true,
                    zIndex: 1000 + (details.signal),
                }}
                onClick={() => {
                    setForcedLine(!forcedLine);
                }}
            />}
        {(lines || forcedLine) && fromSites && fromSites.map(detail => {
            const color = colorMapper(detail.signal, signalLevels);
            return <Polyline
                key={`${detail.location.lat}${detail.location.lng}`}
                onLoad={polyline => {
                    // console.log('polyline: ', polyline)
                }}
                path={[
                    locationObj,
                    {lat: Number(detail.location.lat), lng: Number(detail.location.lng),}
                ]}
                options={{
                    strokeColor: color,
                    strokeOpacity: 0.8,
                    strokeWeight: 3,
                    fillColor: color,
                    fillOpacity: 0.35,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    visible: true,
                    radius: 30000,
                    paths: [
                        locationObj,
                        {
                            lat: Number(detail.location.lat),
                            lng: Number(detail.location.lng),
                        }
                    ],
                    zIndex: 1000 + 100
                }}
            />
        })}
        {tooltip && <BinDetails
            details={details}
            fromSites={fromSites}
            mapType={mapType}
            key={String(locationObj.lat) + "," + String(locationObj.lng)}
            show={tooltip}
            position={{
                lat: locationObj.lat,
                lng: locationObj.lng,
            }}
        />}
    </>
}

function mapStateToProps(reduxState) {
    return {
        lines: reduxState.map.lines,
        mapType: reduxState.map.mapType,
        zoomMultiplier: calcRadiusMultByZoom(reduxState.map.mapZoom),
        highlightOpacity: reduxState.map.highlightOpacity,
    }
}

Bin = React.memo(Bin);
export default Bin;
import React, { forwardRef } from 'react';
import { useProjectState } from "../../Hooks/useProject";
import mongoose from 'mongoose';
import { useAddToast, usePolygonPathState } from "../../Hooks/common";
import Api from '../../controller/ApiManager';
import projectParamsSchema from '../../controller/models/projectParams';
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import FileUploaderBtn from "../Common/FileUploaderBtn"
import styled from "styled-components";
import FileManager from "./FileManager";

const StyledConfigFileManager = styled.div`
    display: none;
`;

function ConfigFileManager(props, ref) {
    const [project, setProject] = useProjectState();
    const [polygonPath, setPolygonPath] = usePolygonPathState();
    const addToast = useAddToast();
    const { t } = useTranslation();

    const allSectors = project.sites
        .flatMap(site => [
            ...site.preDesign.sectors.map(sector => {
                return {
                    siteId: site._id,
                    sectorId: sector._id,
                    txPower: sector.txPower,
                    txLoss: sector.txLoss
                }
            }),
            ...site.postDesign.sectors.map(sector => {
                return {
                    siteId: site._id,
                    sectorId: sector._id,
                    txPower: sector.txPower,
                    txLoss: sector.txLoss
                }
            })
        ]);

    const exportProjectFile = {
        projectName: project.name,
        projectId: project._id,
        sectors: allSectors,
        mapLayersParams: project.mapLayersParams
    };

    function getUpdatedProject(projectFile) {
        if (!(projectFile.projectId === project._id))
            throw Error("The project ID in the file does not match the current project ID");
        let sectorMatch = null;
        const newProjectSites = project.sites
            .map(site => {
                return {
                    ...site,
                    preDesign: {
                        sectors: site.preDesign.sectors
                            .map(sector => {
                                sectorMatch = projectFile.sectors.find(sec => sec.sectorId === sector._id);
                                return {
                                    ...sector,
                                    txPower: sectorMatch?.txPower ? sectorMatch.txPower : sector.txPower,
                                    txLoss: sectorMatch?.txLoss ? sectorMatch.txLoss : sector.txLoss
                                }
                            })
                    },
                    postDesign: {
                        sectors: site.postDesign.sectors
                            .map(sector => {
                                sectorMatch = projectFile.sectors.find(sec => sec.sectorId === sector._id);
                                return {
                                    ...sector,
                                    txPower: sectorMatch?.txPower ? sectorMatch.txPower : sector.txPower,
                                    txLoss: sectorMatch?.txLoss ? sectorMatch.txLoss : sector.txLoss
                                }
                            })
                    }
                }
            });
        return {
            ...project,
            mapLayersParams: projectFile.mapLayersParams,
            sites: newProjectSites
        };
    }

    function validateProjectParams(projectParams) {
        let doc = null;
        let err = null;
        for (const mapLayersParam of projectParams) {
            doc = new mongoose.Document(mapLayersParam, new mongoose.Schema({projectParamsSchema}));
            err = doc.validateSync();
            if (err) throw err;
        }
    }

    async function updateProjectParams(projectId, projectparams) {//////////////////////   מה קורה בשגיאות?  
            const promises = [];
            for (const mapLayersParam of projectparams) {
                promises.push(Api.ProjectParams.editProjectParams(projectId, mapLayersParam.projectParams));
            }
            await Promise.all(promises);
    }

    async function updateSectors(importedSectors) {
            const promises = [];
            const allSectors = project.sites.flatMap(site => [...site.preDesign.sectors, ...site.postDesign.sectors]);
            for (const sector of allSectors) {
                const locallSector = importedSectors.find(importedSector => importedSector.sectorId === sector._id);
                if (locallSector) {
                    promises.push(Api.Sector.editSector({
                        ...sector,
                        txPower: locallSector.txPower,
                        txLoss: locallSector.txLoss,
                    },
                        locallSector.siteId));
                }
            }
            await Promise.all(promises);
    }

    async function updateProject(file) {
        try {
            const parsedFile = JSON.parse(file);
            validateProjectParams(parsedFile.mapLayersParams);
            const newProject = getUpdatedProject(parsedFile);
            // setFetching(true); ? ? ? 
            setProject(newProject);// todo noam code should be awaited with synchronously or Promise.all
            await updateProjectParams(project._id, parsedFile.mapLayersParams);
            await updateSectors(parsedFile.sectors);
        } catch (err) {
            console.error(err);
            addToast(`ERROR: There was an error importing the file`);
        }
    }

    function getFileName() {
        return `${project.name}-${new Date(Date.now()).toLocaleString().split(',')[0].replaceAll(".", "-")}.json`;
    }

    return (
        <FileManager 
            ref={ref}
            fileName={getFileName()}
            data={exportProjectFile}
            onFileChange={updateProject}
            accept=".json"
        />
    );
}

export default forwardRef(ConfigFileManager);
import Validator from "../../controller/Validator";


export function updateDisplayedSectors(mapState, displayedSectorsToUpdate) {// refactor - make it prettier!
    const oldDisplayedSectors = mapState.displayedSectors;
    if (displayedSectorsToUpdate.length === 0){
        return oldDisplayedSectors.map(sector => ({...sector, display: false}));
    }
    const fieldsForEqualityCheck = ['siteId', 'sectorId'];
    if (!displayedSectorsToUpdate.every(Validator.isDisplayedSector)) {
        console.log(displayedSectorsToUpdate);
        throw Error('error updating displayedSectors, "displayedSectorsToUpdate" contains invalid object/s');
    }
    return oldDisplayedSectors.map(oldDS => {
        let existedDisplayedSector = displayedSectorsToUpdate.find(displayedSector =>{ 
            return fieldsForEqualityCheck.every(field => displayedSector[field] === oldDS[field]);
        }
        );
        if (existedDisplayedSector) {
            if (!existedDisplayedSector.hasOwnProperty("display")){ // Temporary patch, needs to be refactored!    
                const newExistedDisplayedSector = {...oldDisplayedSectors.find(sector => 
                    sector.sectorId === existedDisplayedSector.sectorId)};
                newExistedDisplayedSector["display"] = true;  
                newExistedDisplayedSector["antennaModel"] = existedDisplayedSector["antennaModel"];  
                existedDisplayedSector = newExistedDisplayedSector;
            }
            return {...oldDS, ...existedDisplayedSector};
        }
        return {...oldDS, display: false}; 
    });
}
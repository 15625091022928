import {useCallback, useEffect, useRef, useState} from 'react';
import EventEmitter from "../controller/EventEmitter";
import Constants from "../controller/Constants";


export default function useDialogDynamicPositon(dialogRef,dialog){
    const [above, setAbove] = useState(true);
    const checkOffset = useCallback(() => {
        const elDistanceToTop = window.pageYOffset + dialogRef.current.getBoundingClientRect().top;
        const aboveBool = above ?
            elDistanceToTop >= 40 :
            elDistanceToTop > 83 + dialogRef.current.offsetHeight - 13 + 40;
        if (aboveBool !== above) {
            setAbove(aboveBool);
        }
    }, [above]);
    useEffect(() => {
        if (dialogRef.current) {
            checkOffset()
        }
    }, [Boolean(dialogRef.current),dialog]);
    useEffect(() => {
        const id = EventEmitter.subscribe(Constants.events.CENTER_CHANGED, () => {
            if (dialogRef.current) {
                const elDistanceToTop = window.pageYOffset + dialogRef.current.getBoundingClientRect().top;
                const aboveBool = above ?
                    elDistanceToTop >= 40 :
                    elDistanceToTop > (83 - 13) + dialogRef.current.offsetHeight + 40;
                if (aboveBool !== above) {
                    setAbove(aboveBool);
                }
            }
        });
        return () => {
            EventEmitter.unsubscribe(Constants.events.CENTER_CHANGED, id)
        }
    }, [above]);
    //-----------------------start---code to prevent gestures on dialog to affect the map.
    const init = useRef(false);
    useEffect(()=>{
        if(dialog && dialogRef.current && !init.current){
            window.google.maps.OverlayView.preventMapHitsAndGesturesFrom(dialogRef.current);
            init.current = (true)
        }else if(!dialog){
            init.current = false
        }
    },[dialog,dialogRef.current]);
    //-----------------------ent----code to prevent gestures on dialog to affect the map.
    return above;
}
import {useCallback, useEffect} from 'react';
import useProject, {useFetchProject} from "./useProject";
import Api from "../controller/ApiManager/index";
import {setProjects} from "../store/actionCreators/map";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import EventEmitter from '../controller/EventEmitter'
import {useAuth} from "./useAuthentication";
import {useInitDisplayedSectors} from "./displayedSectors";

window.EventEmitter = EventEmitter;

function useFetchProjects() {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const setProjectsRedux = useCallback((projects) => {
        return dispatch(setProjects(projects));
    }, [dispatch]);
    const project = useProject();
    
    useEffect(() => {
        if (user.isAuthenticated) {
            Api.Project.getProjects().then(projects => {
                setProjectsRedux(projects || []);
            }).catch(console.error);
        }
    }, [user.isAuthenticated, project.name]);
}

function useRedirectProject() {
    const isInSpecificProject = useRouteMatch("/projects/:projectId");
    const history = useHistory();
    const params = useParams();
    const {projectId} = params;
    const user = useSelector(state => state.user);
    const projects = useSelector(state => state.map.projects);
    useEffect(() => {
        const hasProjects = Boolean(projects.length);
        const currentProjectExist = projectId ? hasProjects && projects.map(project => project._id).includes(projectId) : true;
        if ((!isInSpecificProject || !currentProjectExist) && hasProjects) {
            history.push('/projects/' + projects[0]._id);
        }
    }, [projectId, user.isAuthenticated, projects.map(project => project._id)]);
}

function useInitApp() {
    useFetchProjects();
    useRedirectProject();
    useInitDisplayedSectors();
    const {fetching: projectFetching, fetched: projectFetched, error: projectError} = useFetchProject();
    const {error: userError, fetched: userFetched, fetching: userFetching} = useAuth();
    const requireLogin = Boolean(userError && userError.message);
    // useEffect(() => {
    // console.log({projectFetching, projectFetched, userFetching, userFetched})
    // }, [projectFetching, projectFetched, userFetching, userFetched]);
    // console.log({projectFetching,projectFetched,userFetching,userFetched});
    return {
        fetching: (projectFetching) || (userFetching),
        projectFetched,
        userFetched,
        error: userError || projectError,
        requireLogin,
    }
}

export default useInitApp;
import {
    ADD_PROJECT,
    MAP_LOADED,
    RESET_PROJECTS,
    SET_DISPLAYED_SECTORS,
    SET_HIGHLIGHT_OPACITY,
    SET_LOCATION_STATS,
    SET_MAP_STATE,
    SET_MAP_TYPE,
    SET_MAP_ZOOM,
    SET_PREDICTION_STATE,
    SET_PROJECTS,
    SET_POLYGON_PATH,
    SET_TOTAL_COST,
    SHOW_COSTS_TABLE,
    UPDATE_DISPLAYED_SECTORS,
} from "../actionTypes";


export function setMapState(mapState) {
    return {type: SET_MAP_STATE, mapState};
}

export function setHighlightOpacity(highlightOpacity) {
    return {type: SET_HIGHLIGHT_OPACITY, highlightOpacity};
}

export function resetProjects() {
    return {type: RESET_PROJECTS};

}

export function setDisplayedSectors(displayedSectors) {
    return {
        type: SET_DISPLAYED_SECTORS,
        displayedSectors
    }
}


export function setTotalCost(totalCost) {
    return {
        type: SET_TOTAL_COST,
        totalCost
    }
}

export function setProjects(projects) {
    return {
        type: SET_PROJECTS,
        projects
    }
}

export function setMapType(mapType) {
    return {
        type: SET_MAP_TYPE,
        mapType
    }
}

export function setMapZoomCreator(mapZoom) {
    return {
        type: SET_MAP_ZOOM,
        mapZoom
    }
}

export function mapLoadedCreator(loaded) {
    return {
        type: MAP_LOADED,
        loaded,
    }
}

export function setPredictionState(predictionState) {
    return {
        type: SET_PREDICTION_STATE,
        predictionState
    }

}

export function setLocationsStats(locationStats) {
    return {
        type: SET_LOCATION_STATS,
        locationStats
    }
}

export function showCostsTable(isCostsTable) {
    return {
        type: SHOW_COSTS_TABLE,
        isCostsTable
    }
}

export function addProject(project) {
    return {
        type: ADD_PROJECT,
        project
    }
}

export function setPolygonPath(polygonPath) {
    return {
        type: SET_POLYGON_PATH,
        polygonPath
    }
}

export function updateDisplayedSectors(displayedSectors){
    return {
        type:UPDATE_DISPLAYED_SECTORS,
        displayedSectors,
    }
}
import validate from 'validate.js';
import Constants from './Constants';

function testFields(fields, obj) {
    return fields.every(({field, check}) => {
        if (obj.hasOwnProperty(field)){
            let res;
            if (field.includes('.')) {
                const innerField = field.split('.', 1)[0];
                if (!validate.isObject(obj[innerField])) {
                    console.warn(`field "${innerField}" does'nt exist in obj: ${JSON.stringify(obj)}.\n validation failed!`)
                    return false;
                }
                res = testFields([{field: field.split('.').slice(1).join('.'), check}], obj[innerField])

            }
            else {
                res = check(obj[field]);
            }
            if (!res) {
                console.warn(`field : "${field}" of object :${JSON.stringify(obj)} is invalid!`)
            }
            return res;
        }
        return true;
    })
}


class Validator extends validate {
    static isEmail(email) {
        const validEmailRes = validate.single(email, {presence: true, email: true});
        if (!validEmailRes) {
            return true;
        } else {
            console.error(new Error(validEmailRes[0]));
            return false;
        }
    }

    static isSite(site) {
        return validate.isString(site.displayName) && this.isLocation(site.location) && !validate.isEmpty(site._id);
    }

    static isNewSite(site) {
        return validate.isString(site.displayName) && this.isLocation(site.location);
    }

    static isLocation(location) {
        return validate.isNumber(location.lat) && validate.isNumber(location.lng);
    }

    static isSiteWithHeights(site) {
        return this.isSite(site) && site.preDesign.sectors && site.preDesign.sectors.length > 0 &&
            site.preDesign.sectors.every(height => this.isSector(height));
    }

    static isToast(toastDetails) {
        return !validate.isEmpty(toastDetails.message || toastDetails.body)
    }

    static isSector(height) {
        return (
            (!validate.isEmpty(height.height) || height.height === 0) &&
            (!validate.isEmpty(height.cost) || height.cost === 0) &&
            validate.isInteger(Number(height.height)) &&
            validate.isInteger(Number(height.cost)) &&
            validate.isArray(height.antennaModels) &&
            height.antennaModels.every(sType => Constants.smartTypeStrings.includes(sType))
        );
    }

    static isHeightWithFile(height) {
        return this.isSector(height) && !this.isEmpty(height.file);
    }

    static testItems(array, testFunc) {
        return Array.from(array).reduce((acc, item) => acc && testFunc(item), true);
    }

    static parseToNumberIfCan(val) {
        return validate.isString(val) && !isNaN(Number(val)) && !validate.isEmpty(val) ? Number(val) : val;
    }

    static isProject(project) {
        return true;
    }

    static isDisplayedSector(displayedSector) {
        const isStringOrNull = (val) => validate.isString(val) || val === null;
        const orUndefined = (check) => val => check(val) || val === undefined;
        const fields = [
            {field: "siteId", check: orUndefined(validate.isString)},
            {field: "sectorId", check: orUndefined(validate.isString)},
            {field: "antennaModel", check: orUndefined(validate.isString)},
            {field: "display", check: orUndefined(validate.isBoolean)},
            {field: "conversion.from", check: orUndefined(isStringOrNull)},
            {field: "conversion.to", check: orUndefined(isStringOrNull)},
        ];
        return testFields(fields, displayedSector);
    }

    static isSiteDetails(siteDetails) {
        const basicSite = this.isSite(siteDetails);
        const {displayParams, parameters} = siteDetails;
        const {txPower, txLoss, antennaGain} = parameters;
        const {txPower: txPowerDP, txLoss: txLossDP, antennaTypeCode} = displayParams;
        const integers = [txPower, txLoss, txPowerDP, txLossDP, antennaGain];
        return basicSite && integers.every(integer => validate.isNumber(integer)) && validate.isString(antennaTypeCode);
    }
}

export default Validator;
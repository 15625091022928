import React, {useState, useRef} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal'
import styled from "styled-components";
import SitesManager from "../Site/SitesManager";
import Api from "../../controller/ApiManager/index";
import {setDialogSite} from "../../store/actionCreators/general";
import {addToast} from "../../store/actionCreators/general";
import {useFetchCallback} from "../../Hooks/useFetchCallback";
import FormCheck from 'react-bootstrap/FormCheck';
import Constants from "../../controller/Constants";
import {useProjectState} from "../../Hooks/useProject";
import {SET_CURRENT_DIALOG} from "../../store/actionTypes";
import {async} from 'validate.js';
import {addProject} from "../../store/actionCreators/map";
import {useDispatch} from 'react-redux';

const ModalContent = styled.div`
        width:40vw;
        .checkbox .form-check-input{
            position: relative;
            bottom:-7px;
            margin-top: 10px;
        }
`;

const SubmitButton = styled(Button)`
    &.btn[type=submit]{
      margin-top: 20px !important;
    }
`;

function AddProject({onClose: closeDialog, onBack, addToast, setDialogSite, addProject}) {
    const [project, setProject] = useProjectState();
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const {t} = useTranslation();
    const isChecked = useRef(null);

    const handleSubmit = useFetchCallback(async (ev) => {
            ev.preventDefault();
            const project = await Api.Project.addProject(name);
            addToast(`Successfully added Project "${project.name}" to Database!`);
            addProject({...project});
            if (isChecked.current.checked ){
                setProject(project)
                openEditProjectDialog()        
                }else{
                    closeDialog();
                }
        }, [name, closeDialog],
        {setError: err => (closeDialog())}
    );

    function openEditProjectDialog() {
        dispatch({
            type: SET_CURRENT_DIALOG,
            dialog: Constants.modals.EDIT_PROJECT
        })
        
    }
    return (
        <ModalContent>
            <Modal.Header closeButton>
                <div className="section-title">
                    <h2>{t('Add_Project_Title')}</h2>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form className="form inputs-underline" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <Form.Group>
                                <Form.Label htmlFor="project_name" column={false}>{t('Project_Name')}</Form.Label>
                                <Form.Control type="text" name="project_name"
                                              placeholder="Last name"
                                              onChange={(ev) => setName(ev.target.value)}
                                              value={name}
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <Form.Group  className="checkbox">
                        <Form.Check  ref={isChecked}  type="checkbox" label="Go to the new project" />
                    </Form.Group>
                    <Form.Group className="center">
                        <SubmitButton type={'submit'} variant={'primary'}
                                      className="width-100">
                            {t('Add_Project')}
                        </SubmitButton>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </ModalContent>
    )
}

export default connect(() => {
}, {setDialogSite, addToast, addProject})(AddProject);
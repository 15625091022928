import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setTotalCost as setReduxTotalCost} from "../store/actionCreators/map";
import useProject from "./useProject";
import {useAddToast} from "./common";
import {getSiteSectors} from "../controller/dataOperations";

function detectChange(project, displayedSectors) {
    return `${project._id || project.name}:${displayedSectors.sort(function (a, b) {
        if (a.sectorId < b.sectorId) {
            return -1;
        }
        if (a.sectorId > b.sectorId) {
            return 1;
        }
        return 0;
    }).map(({sectorId, antennaModel, display}) => [sectorId, antennaModel, display? "display": "notDisplay"].join(', ')).join(';')}`; 
}

function useTotalCost() {
    const project = useProject();
    const displayedSectors = useSelector(state => state.map.displayedSectors);
    const heightChangedString = detectChange(project, displayedSectors);
    const totalCost = useSelector(state => state.map.totalCost);
    const addToast = useAddToast();
    const dispatch = useDispatch();
    const setTotalCost = useCallback((totalCost) => dispatch(setReduxTotalCost(totalCost)), [dispatch]);
    const spreadedSectors = project.sites.map(site => {
        try {
            return getSiteSectors(site).map((sector) => {
                const list = [sector.cost, ...Object.values(sector.antennaPrices)].join(',');
                return list;
            }).join(';')
        } catch (e) {
            console.error(e);
            addToast(Error('there was error calculating the total cost'));
            return null;
        }
    }).join(':');

    useEffect(() => {
        try {
            const totalCost = displayedSectors.filter(displayedSector => displayedSector.display)
                .reduce((accm, displayedSector) => {
                    const site = project.sites.find(site =>
                        (String(site._id) === String(displayedSector.siteId)));
                    const sector = getSiteSectors(site).find(sector =>
                        (sector._id === displayedSector.sectorId));
                    const antennaPrice = sector?.antennaPrices[displayedSector.antennaModel];
                    return accm + Number(sector?.cost) + Number(antennaPrice);
                }, 0);
            setTotalCost(totalCost);
        } catch (e) {
            console.error(e);
        }
    }, [heightChangedString, spreadedSectors]);

    return totalCost;
}

export default useTotalCost;
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import GoogleMap from './MapContainer'
import {useDispatch, useSelector} from "react-redux";
import Constants from "../../controller/Constants";
import LoaderSpinner from "../Loaders/LoaderSpinner";
import BestServerMap from "./BestServerMap";
import C2IMap from "./C2IMap";
import {mapLoadedCreator} from "../../store/actionCreators/map";
import ErrorBoundary from "../Common/ErrorBoundary";
import EventEmitter from "../../controller/EventEmitter";

const StyledMap = styled(GoogleMap)` 
    height: 100%;
    width: 100%;
`;


function Map() {
    const {mapType, mapLoaded} = useSelector(mapStateToProps);
    const [mapScriptLoaded, setScriptLoaded] = useState(false);
    const dispatch = useDispatch();
    let CurrentMap;
    const {BEST_SERVER, C2I} = Constants.mapTypes;
    switch (mapType) {
        case BEST_SERVER: {
            CurrentMap = BestServerMap;
            break;
        }
        case C2I: {
            CurrentMap = C2IMap;
            break;
        }
        default: {
            CurrentMap = BestServerMap;
        }
    }
    useEffect(() => {
        if (!mapLoaded && mapScriptLoaded) {
            window.mainMap.setCenter({lat: 0, lng: 0});
            dispatch(mapLoadedCreator());
        }
    }, [mapScriptLoaded, mapLoaded]);
    return (
        <StyledMap
            onLoad={(map) => {
                setScriptLoaded(true);
                window.mainMap = map;
            }}
            onBoundsChanged={() => EventEmitter.dispatch(Constants.events.CENTER_CHANGED)}
        >
            <ErrorBoundary>
                {mapLoaded ? <CurrentMap/> : <LoaderSpinner/>}
            </ErrorBoundary>
        </StyledMap>
    )
}

function mapStateToProps(reduxState) {
    return {
        mapType: reduxState.map.mapType,
        mapLoaded: reduxState.map.loaded,
    }
}

export default Map;
import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "../Common/Buttons/Button";
import styled from "styled-components";
import RadioList from "../Common/Inputs/RadioList";
import Collapse from "react-bootstrap/Collapse";
import {getSectorLabel} from "../../controller/mapper";
import MultiSelectList from "../Common/MultiSelect/MultiSelectList";
import useProject from "../../Hooks/useProject";
import {useTranslation} from "react-i18next";
import {useSetCurrentDialog} from "../../Hooks/common";
import {useDisplayedSectors, useDisplayedSectorsActions} from "../../Hooks/displayedSectors";
import {findSectorOfProject, getSiteSectors} from "../../controller/dataOperations";


const ModalContent = styled.div`
  background-color: white;
  .radio-scope-choice{ 
    padding-top: 20px;
    padding-bottom: 0;
    .radio-form-group.col{
      padding-left: 40px;
      justify-content: flex-start;
    }
  }
`;

const ButtonsGroup = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;

const selectionScopes = ['all', 'some'];
const [ALL, SOME] = selectionScopes;


function TechConversion({}) {
    const project = useProject();
    const [selectionScope, setSelectionScopes] = useState(SOME);
    const [selectedSectors, setSelectedSectors] = useState([]);
    const {t} = useTranslation();
    const setCurrentDialog = useSetCurrentDialog();
    const displayedSectors = useDisplayedSectors();
    const {updateDisplayedSectors} = useDisplayedSectorsActions();
    const goOnClick = () => {
        const displayedSectorsDetails = selectedSectors.flatMap(({sectorId, siteId, antennaModel, checked}) => {
            return ({
                sectorId,
                siteId,
                conversion: {
                    from: (checked || selectionScope == ALL) ? '4G' : null,
                    to: (checked || selectionScope == ALL) ? '5G' : null
                },
            });
        });
        debugger;
        updateDisplayedSectors(displayedSectorsDetails);
        setCurrentDialog(null);
    };
    useEffect(() => {
        const sectorsToSelect = project.sites.flatMap(site => {
            const siteDisplayedSectors = displayedSectors.filter(displayedSector => displayedSector.siteId === site._id);
            return [...site.preDesign.sectors, ...site.postDesign.sectors].map(sector => {
                const displayedSector = siteDisplayedSectors.find(displayedSector => displayedSector.sectorId === sector._id);
                return ({
                    sectorId: sector._id,
                    siteId: site._id,
                    value: sector._id,
                    label: `${site.displayName} - ${getSectorLabel(sector)}`,
                    checked: Boolean(displayedSector?.conversion.from && displayedSector?.conversion.to),
                })
            });
        }).sort((a, b) => a.label.localeCompare(b.label));
        setSelectedSectors(sectorsToSelect);
    }, [project]);
    return (
        <ModalContent>
            <Modal.Header closeButton>
                <h2>{t('Tech_Conversion')}</h2>
            </Modal.Header>
            <Modal.Body>
                <RadioList
                    className={'radio-scope-choice'}
                    currentValue={selectionScope}
                    values={selectionScopes}
                    onItemClick={setSelectionScopes}
                    renderLabels={val => t(val[0].toUpperCase() + val.slice(1) + '_Sectors_gs')}
                    label={t('Choose_Scope')}
                />
                <Collapse in={selectionScope === SOME}>
                    <div className={'select-container'}>
                        <MultiSelectList
                            onSelectAll={() => {
                                const allSelected = selectedSectors.every(sector => sector.checked);
                                setSelectedSectors(selectedSectors.map(sector => ({
                                    ...sector,
                                    checked: !allSelected
                                })))
                            }}
                            onSelect={(value) => setSelectedSectors(selectedSectors.map(sector => sector.value === value ? {
                                ...sector,
                                checked: !sector.checked
                            } : sector))}
                            optionsList={selectedSectors}
                            inputType={'checkbox'}
                        />
                    </div>
                </Collapse>
                <ButtonsGroup>
                    <Button
                        onClick={() => {
                            setCurrentDialog(null);
                        }}
                        type={'submit'}
                        variant={'outline'}>
                        {t('Quit')}
                    </Button>
                    <Button
                        onClick={goOnClick}
                        type={'submit'}
                        color={'primary'}
                    >
                        {t('Go_On')}
                    </Button>
                </ButtonsGroup>
            </Modal.Body>
        </ModalContent>
    );
}

export default TechConversion;
import {useEffect, useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
import styled from "styled-components";
import ModalDialog from "react-bootstrap/ModalDialog";
import {useTranslation} from "react-i18next";
import useDialogDynamicPositon from "../../Hooks/useDialogDynamicPosition";
import {getSiteSectors, sortSmartTypeString} from "../../controller/dataOperations";
import {useDisplayedSectors, useDisplayedSectorsActions} from "../../Hooks/displayedSectors";
import Tooltip from "../Common/Tooltip";
import ButtonsSelect from "../Common/Buttons/ButtonsSelect";
import Constants from "../../controller/Constants";
import SectorsDialogBody from "./SectorsDialogBody";
import {getSectorLabel} from "../../controller/mapper";

const {siteModes} = Constants;

const StyledModalDialog = styled(ModalDialog)`
  cursor: default;
  position: absolute !important;
  ${(props) => (props.aboveProp ? `bottom: 83px` : `top: -12px`)};
  left: -81px;
  z-index: 10;
  button.close {
    span[aria-hidden] {
      position: static;
      top: unset;
      right: unset;
      transform: none;
    }
  }
 
  button.close:focus {
    outline: none;
  }
  .modal-content {
    width: 175px;
  }
  .select-button-group  {
    width:100%;
    margin-left:5px;
    margin-right:10px;
    display:flex;
    justify-content: stretch;
    .select-button ,.select-button:active {
      flex-grow: 1;
      padding: 2px 10px;
    }
  }
  .modal-header .close{
      padding: 0px;
      margin: 0px;
  }
`;

const EmptyDiv = styled("div")`
  width: 35px;
`;

function getAntennaModels(sectors, sectorActive) {
    return sectors.find((item) => item._id === sectorActive)?.antennaModels.sort(sortSmartTypeString);
}

//todo: refactor this component to use displayed sectors instead of origin sectors from project.
function SectorsDialog({site, displayed: dialog, onClose: handleCloseDialog}) {
    const allSectors = getSiteSectors(site);
    const globalDisplayedSectors = useDisplayedSectors();
    const [localSectors, setLocalSector] = useState(initLocalSectors());
    const initMode = site.preDesign.sectors.length === 0 ? siteModes.postdesign : siteModes.predesign;
    const [mode, setMode] = useState(initMode);
    const dialogRef = useRef(null);
    const {t} = useTranslation();
    const above = useDialogDynamicPositon(dialogRef, dialog);
    const [showTooltip, setShowTooltip] = useState(false);
    const {updateDisplayedSectors} = useDisplayedSectorsActions();

    useEffect(() => {
        const globalDisplayedSectorsInSite = globalDisplayedSectors.filter((displayedSector) => displayedSector.siteId === site._id);
        const newLocalSectors = initLocalSectors().map((localSector) => {
            const displayedSector = globalDisplayedSectorsInSite.find(({sectorId}) => sectorId === localSector.sectorId) || null;
            const isSelected = Boolean(localSectors.find(({sectorId}) => sectorId === localSector.sectorId)?.display);
            return {
                ...localSector,
                display: Boolean(displayedSector?.display) || isSelected,
                active: getActiveSectorId() === localSector.sectorId,
                antennaModel: displayedSector ? displayedSector.antennaModel : Constants.antennaModelStrings[0],
                conversion: displayedSector ? displayedSector.conversion : localSector.conversion,
            };
        });
        setLocalSector(newLocalSectors);
    }, [globalDisplayedSectors]);

    function initLocalSectors() {
        const newLocalSectors = allSectors.map((sector, i) => ({
            siteId: site._id,
            sectorId: sector._id,
            antennaModel: Constants.antennaModelStrings[0],
            display: false,
            active: i === 0,
            conversion: {from: null, to: null},//TODO: add site mode
            siteMode: sector.siteMode
        }));
        return newLocalSectors;
    }

    function onSectorActive(sectorId) {
        const newLocalHeights = localSectors.map((el) => ({...el, active: el.sectorId === sectorId}));
        setLocalSector(newLocalHeights);
    }

    function onModelActive(antennaModel) {
        const newLocalHeights = localSectors.map((el) => el.active ? {...el, antennaModel: antennaModel} : el);
        setLocalSector(newLocalHeights);
    }

    function getActiveSectorId() {
        const isSomeSectorActive = localSectors.some(sector => !sector.active);
        if (!isSomeSectorActive && localSectors.length > 0){ // No sector is active
            localSectors[0].active = true;
            return localSectors[0].sectorId
        }
        const displayedSector = localSectors.find((elem) => elem.active);
        return displayedSector ? displayedSector.sectorId : null;
    }

    function getActiveSector() {
        return allSectors.find(sector => sector._id === getActiveSectorId());
    }

    function handleTooltip(key) {
        const show = key === "antenna types";
        setShowTooltip(show);
    }

    function onSelectSector(sectorId) {
        const mapSector = (localSector) => localSector.sectorId === sectorId ?
            {...localSector, display: !localSector.display} : localSector;
        setLocalSector(prevSectors => prevSectors.map(mapSector));
    }

    function isSectorChecked(sectorId) {
        return localSectors.some((localSector) => Boolean(localSector.sectorId === sectorId && localSector.display));
    }

    function isTypeChecked(type) {
        return localSectors.find((elem) => elem.active).antennaModel === type;
    }

    function applyChanges() {
        updateDisplayedSectors(localSectors);
    }

    function isActiveSelected() {
        const activeSectorId = getActiveSectorId();
        const isDisabled = !localSectors.find(({sectorId}) => sectorId === activeSectorId)?.display;
        return isDisabled;
    }

    function createSelectSectorLabel(sector) {
        const displayedSector = globalDisplayedSectors.find(displayedSector => displayedSector.sectorId === sector._id);
        const convesionPostfix = displayedSector?.conversion?.to ? ` (${displayedSector.conversion.to.toUpperCase()})` : '';
        return getSectorLabel(sector) + convesionPostfix;
    }

    return dialog && <StyledModalDialog
        aboveProp={above}
        size={"md"}
        scrollable={true}
        ref={dialogRef}>
        {showTooltip && <Tooltip
            text={getSectorLabel(getActiveSector())}
            placement="right"
            visible
            style={{top: 44, left: 184}}>
        </Tooltip>}
        <Modal.Header
            className={'modal-header-sectors'}
            style={{padding: 8}}
            closeButton
            onHide={()=>{setShowTooltip(false); handleCloseDialog()}}>
            <ButtonsSelect
                values={[
                    {
                        value: 'Predesign',
                        label: t('pre_'),
                        checked: mode === 'Predesign',
                        disabled: site.preDesign.sectors.length === 0
                    },
                    {
                        value: 'Postdesign',
                        label: t('post_'),
                        checked: mode === 'Postdesign',
                        disabled: site.postDesign.sectors.length === 0
                    }]}
                onSelect={(item) => setMode(item.value)}>
            </ButtonsSelect>
        </Modal.Header>
        <SectorsDialogBody
            allSectors={allSectors}
            handleTooltip={handleTooltip}
            typesOptions={getAntennaModels(allSectors, getActiveSectorId()).map((item) => ({
                value: item,
                label: t(`${item}_hd`),
                checked: isTypeChecked(item),
            }))}
            sectorsOptions={allSectors.filter(sector => sector.siteMode === mode).map((sector) => ({
                value: sector._id,
                label:createSelectSectorLabel(sector),
                checked: isSectorChecked(sector._id),
            }))}
            active={getActiveSectorId()}
            onSectorActive={onSectorActive}
            onSelectSector={onSelectSector}
            disabled={isActiveSelected()}
            onModelActive={onModelActive}/>
        <Modal.Footer style={{padding: "10px 16px"}}>
            <Button onClick={handleCloseDialog} variant="secondary"> Close </Button>
            <EmptyDiv/>
            <Button onClick={applyChanges} variant="primary"> Apply </Button>
        </Modal.Footer>
    </StyledModalDialog>;
}

export default SectorsDialog;